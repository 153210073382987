import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import styled, { css } from 'styled-components'

import { DownChevronIcon } from '@/components/_svg/icons/ChevronIcon'
import { useOnClickOutside } from '@/hooks/use-onClickOutside'
import { IPropertyValue } from '@/types/main'
import { DEVICE_WIDTH } from '@/utils/constants'

export interface IDropdownOption<T = string | number> {
  value: T
  label: string
}

interface IProps {
  options: IDropdownOption[]
  onOptionSelectFunc?: (option: IDropdownOption) => void
  selectedValue?: string | number
  ItemRenderer?: React.ElementType
  ValueRenderer?: React.ElementType
  noEmptySelection?: boolean
  className?: string
}

export const Dropdown: React.FC<IProps> = ({
  options,
  onOptionSelectFunc,
  selectedValue,
  ItemRenderer = DefaultItemRenderer,
  ValueRenderer = DefaultValueRenderer,
  noEmptySelection = false,
  className,
}) => {
  const [selectedOption, setSelectedOption] = useState<IDropdownOption>()
  const [isOpen, setIsOpen] = useState(false)
  const selectRef = useRef(null)
  useOnClickOutside(selectRef, () => setIsOpen(false))

  useEffect(() => {
    if (!noEmptySelection && !selectedValue) {
      return
    }

    const selOption = options.find(
      (option: IDropdownOption) => option.value === selectedValue
    )
    // Fallback to first option
    setSelectedOption(selOption ?? options[0])
  }, [selectedValue])

  useEffect(() => {
    if (!selectedOption) {
      return
    }

    onOptionSelectFunc?.(selectedOption)
  }, [selectedOption])

  const handleOptionSelected = (option: IDropdownOption) => () => {
    setSelectedOption(option)
    setIsOpen(false)
  }

  if (selectedValue && !selectedOption) {
    return null
  }

  return (
    <Wrapper className={className} ref={selectRef} $isOpen={isOpen}>
      <ValueRenderer
        isOpen={isOpen}
        onClick={() => {
          if (options.length === 1) {
            return
          }
          setIsOpen((prev) => !prev)
        }}
        option={selectedOption}
        optionsLength={options.length}
      />

      <DropdownContent>
        <DropdownList $isOpen={isOpen}>
          {options
            .filter((option: IDropdownOption) => option.value !== selectedOption?.value)
            .map((option: IDropdownOption) => (
              <ItemRenderer
                option={option}
                onClick={handleOptionSelected(option)}
                key={option.value}
              />
            ))}
        </DropdownList>
      </DropdownContent>
    </Wrapper>
  )
}

export interface IDropdownOptionPropValue extends IDropdownOption {
  propValue: IPropertyValue
}

export const buildPropValueListDropdownOptions = (
  propValues: IPropertyValue[]
): IDropdownOptionPropValue[] =>
  propValues.map((propValue) => ({
    label: propValue.title,
    value: propValue._id,
    propValue,
  }))

export interface IItemRendererProps<T = IDropdownOption> {
  option: T
  onClick?: () => void
  className?: string
}

export const DefaultItemRenderer: React.FC<IItemRendererProps> = ({
  option,
  onClick,
  className,
}) => (
  <DropdownListItem onClick={onClick} className={className}>
    {option.label}
  </DropdownListItem>
)

export interface IValueRendererProps<T = IDropdownOption> {
  option: T
  optionsLength?: number
  onClick: () => void
  isOpen: boolean
  className?: string
}

export const DefaultValueRenderer: React.FC<IValueRendererProps> = ({
  option,
  onClick,
  isOpen,
  className,
}) => (
  <DropdownHeading $isOpen={isOpen} onClick={onClick} className={className}>
    {option?.label || (
      <FormattedMessage
        defaultMessage="Sélectionner"
        description="Dropdown: default select"
      />
    )}
    <DropdownChevronIcon $isOpen={isOpen} />
  </DropdownHeading>
)

const Wrapper = styled.div<{ $isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: ${({ theme, $isOpen }) =>
    $isOpen ? theme.zIndex.dropdownMobile + 1 : theme.zIndex.dropdownMobile};

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    z-index: ${({ theme, $isOpen }) =>
      $isOpen ? theme.zIndex.dropdownTablet + 1 : theme.zIndex.dropdownTablet};
  }
`
export const DropdownHeading = styled.div<{ $isOpen: boolean }>`
  align-items: center;
  border: 1px solid ${({ theme, $isOpen }) => ($isOpen ? theme.color.greyLight : 'none')};
  border-bottom: 0;
  border-radius: ${({ theme }) => theme.spacing.ms};
  cursor: pointer;
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs};
  justify-content: space-between;
  padding: ${({ theme, $isOpen }) =>
    $isOpen ? theme.spacing.s : `0 ${theme.spacing.s}`};
  position: relative;
  width: 100%;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}

  &:hover {
    color: ${({ theme }) => theme.color.terracotaDark};
  }
`
export const DropdownChevronIcon = styled(DownChevronIcon)<{ $isOpen: boolean }>`
  fill: ${({ theme }) => theme.color.terracota};
  height: ${({ theme }) => theme.spacing.s};
  transform: rotate(${({ $isOpen }) => ($isOpen ? '90deg' : null)});
  transition: all 300ms;
  width: ${({ theme }) => theme.spacing.s};
`
const DropdownContent = styled.div`
  height: 0;
`
const DropdownList = styled.ul<{ $isOpen: boolean }>`
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.greyLight};
  border-bottom-left-radius: ${({ theme }) => theme.spacing.ms};
  border-bottom-right-radius: ${({ theme }) => theme.spacing.ms};
  border-top: 0;
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  text-align: left;
  user-select: none;
`
const DropdownListItem = styled.li`
  padding: 0 ${({ theme }) => theme.spacing.s};

  &:hover {
    color: ${({ theme }) => theme.color.terracotaDark};
    cursor: pointer;
  }
`
